import { getDirectInfo } from '@/api/ogdirect-api';
import { DIRECT_INFO_STATUS } from '@officeguru/webapp-shared/src/configuration/og-direct-status';
import store from '../../store';
import { getSubAccountPublic } from '@/api/officehub';

const routes = [
  {
    path: 'password/reset',
    name: 'Reset password',
    alias: '/password/reset',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/pages/public-single-reset-password.vue'
      ),
  },
  {
    path: 'partners/:id/profile',
    name: 'Public Partner Profile',
    component: () =>
      import('@/views/partners/pages/partners-single-profile.vue'),
  },
  {
    path: 'contracts/:contractId',
    name: 'Lead contract',
    alias: '/contracts/:contractId/new',
    redirect: { name: 'lead-contract-conversation' },
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/contracts-v2/pages/contract-v2-single.vue'
      ),
    children: [
      {
        path: 'conversation',
        name: 'lead-contract-conversation',
        component: () =>
          import(
            /* webpackChunkName: "public" */ '@/views/contracts-v2/pages/contract-v2-single-conversation.vue'
          ),
        meta: {
          public: true,
          sidebarBranding: true,
        },
      },
      {
        path: 'contract',
        name: 'lead-contract-contract',
        component: () =>
          import(
            /* webpackChunkName: "public" */ '@/views/contracts-v2/pages/contract-v2-single-contract.vue'
          ),
        meta: {
          public: true,
          sidebarBranding: true,
        },
      },
      {
        path: 'activity',
        name: 'lead-contract-activity',
        component: () =>
          import(
            /* webpackChunkName: "public" */ '@/views/contracts-v2/pages/contract-v2-single-activity.vue'
          ),
        meta: {
          public: true,
          sidebarBranding: true,
        },
      },
    ],
  },
  {
    path: 'share/contracts/:contractId',
    name: 'Shared contract',
    component: () => import('@/views/share/pages/shared-contract.vue'),
    meta: {
      noHomeLink: true,
    },
  },
  {
    path: 'pulse-review/:id',
    name: 'Pulse review',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/pulse-review/pulse-review-list.vue'
      ),
    meta: {
      bareLayout: true,
    },
  },
  {
    path: 'login',
    alias: '/login',
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/pages/public-single-login.vue'
      ),
    meta: {
      bareLayout: true,
    },
  },
  {
    path: 'logout',
    alias: '/logout',
    name: 'Logout',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/pages/public-single-logout.vue'
      ),
    meta: {
      bareLayout: true,
    },
  },
  {
    path: '/services/*',
    beforeEnter: (to, from, next) => {
      next('/');
    },
  },
  {
    path: '/direct/:token',
    name: 'direct',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/ogdirect/ogdirect-confirm.vue'
      ),
    meta: {
      bareLayout: true,
    },
    // eslint-disable-next-line consistent-return
    beforeEnter: async (to, from, next) => {
      const { data } = await getDirectInfo(to.params.token);

      // only show flow when customer is not yet created, else send customer
      // to task (either login w. redirect or directly to task or to the
      // page telling that the task is gone [e.g. when it's not theirs])
      if (data.data.status !== DIRECT_INFO_STATUS.WAITING_FOR_CUSTOMER) {
        window.location.href = `/tasks/${data.data.task.id}`;
        return false;
      }

      // as we disabled the logout on this public route we now need to logout,
      // as we identified a new user with the request above
      await store.dispatch('auth/logout');

      next();
    },
  },
  {
    path: '/subaccount/:token',
    name: 'subaccount',
    component: () =>
      import(
        /* webpackChunkName: "public" */ '@/views/public/officehub/subaccount-finish.vue'
      ),
    meta: {
      bareLayout: true,
    },
    beforeEnter: async (to, from, next) => {
      const { data } = await getSubAccountPublic({ token: to.params.token });
      const customer = data.data;

      // when the invitation wasn't finished yet, make sure that we don't have
      // a logged in user and then start the account finish flow
      if (!customer.activated) {
        await store.dispatch('auth/logout');
      }

      await (customer.activated ? next('/') : next());
    },
  },
];

export default routes.map((route) => ({
  ...route,
  meta: { ...route.meta, public: true },
}));
