import api from '../plugins/api';
import { posthog } from '../../plugins/posthog';

import cookies from 'js-cookie';
import { env } from '@officeguru/webapp-shared/src/helpers/environment';

const COOKIE_NAME = `officeguru_api_token_${env.getEnvironmentName()}`;
// e2e tests run on localhost
const DOMAIN_NAME =
  window.location.hostname === 'localhost'
    ? 'localhost'
    : `.${env.getRootDomain()}`;

export default {
  namespaced: true,
  state: {
    access_token: cookies.get(COOKIE_NAME) || '',
  },
  getters: {
    isAuthenticated(state) {
      return !!state.access_token;
    },
    accessToken(state) {
      return state.access_token;
    },
  },
  mutations: {
    updateToken(state, token) {
      // @TODO: Cleanup old tokens
      localStorage.removeItem('access_token');

      cookies.set(COOKIE_NAME, token, {
        path: '/',
        domain: DOMAIN_NAME,
        expires: 400, // 400 days, maximum possible
        sameSite: 'strict',
      });
      state.access_token = token;
    },
    clearToken(state) {
      // @TODO: Cleanup old tokens
      localStorage.removeItem('access_token');

      cookies.remove(COOKIE_NAME, {
        path: '/',
        domain: DOMAIN_NAME,
      });
      state.access_token = null;
    },
  },
  actions: {
    login({ commit, dispatch }, credentials) {
      return api.post('/login', credentials).then(({ data }) => {
        if (data.data.type !== 'customer') {
          throw new Error('Wrong user type!');
        }
        commit('updateToken', data.data.token);
        dispatch('loading/startInitialLoad', null, { root: true });
      });
    },
    loginTemp({ commit }, credentials) {
      return api.post('/login', credentials).then(({ data }) => {
        if (data.data.type !== 'customer') {
          throw new Error('Wrong user type!');
        }
        commit('updateToken', data.data.token);
      });
    },
    async loginWithSSO({ commit, dispatch }, { token, vendor }) {
      const vendors = ['google', 'microsoft'];
      if (!vendors.includes(vendor)) {
        throw new Error('Invalid vendor');
      }
      const { data } = await api.post(`/customer/login/${vendor}`, { token });
      commit('updateToken', data.data.token);
      dispatch('loading/startInitialLoad', null, { root: true });
    },
    logout({ commit }) {
      // start the logout w. a valid token
      const post = api.post('/logout');
      // remove token while the logout request is running
      commit('clearToken');
      posthog.reset();
      return post;
    },
  },
};
