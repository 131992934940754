<template>
  <div v-if="!sidebarBranding" data-cy="layout-public" class="public-page">
    <div class="navbar" v-if="!isBare">
      <div class="navbar__wrapper">
        <div class="navbar__inner">
          <div id="header-teleport-target"></div>
          <a
            class="logo"
            href="https://officeguru.dk/"
            :style="noHomeLink ? 'pointer-events: none;' : ''"
          >
            <Logo class="logo__image" />
            <span class="logo__text">officeguru</span>
          </a>
        </div>
      </div>
    </div>
    <div class="public-page__inner">
      <router-view />
    </div>
    <div class="public-footer" v-if="!isBare">
      <div class="public-footer__details">
        <Logo class="logo" />
        <div class="headline">Officeguru</div>
        <p class="description">
          Bryggervangen 55, 4. tv.<br />
          2100 København Ø<br />
          Tel: <a href="tel:+4543991529">43 99 15 29</a>
        </p>
      </div>
    </div>
  </div>
  <div v-else data-cy="layout-public" class="public-page branded-sidebar">
    <BrandingSidebar />
    <div class="public-page__inner">
      <router-view />
    </div>
  </div>
</template>

<script>
import { Logo } from '@officeguru/components-vue3';
import BrandingSidebar from '@/components/branding-sidebar.vue';

export default {
  components: {
    Logo,
    BrandingSidebar,
  },
  computed: {
    isBare() {
      return this.$route.meta?.bareLayout;
    },
    sidebarBranding() {
      return this.$route.meta?.sidebarBranding;
    },
    noHomeLink() {
      return this.$route.meta?.noHomeLink;
    },
  },
  beforeMount() {
    // For rendering the contract we need to check the value of a
    // split feature flag. We therefore need to setup and call the split
    // API with some fake user data
    // @SEE https://officeguru.atlassian.net/browse/OG-1797
    const user = { id: 0, customer: { lead: true } };
    this.$store.commit('users/set_me', user);
    this.$store.commit('users/set_single', user);
    this.$store.dispatch('split/setup', user.id);
    this.$store.dispatch('settings/setup', { isPublic: true });
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/main';

$navbarHeight: 80px;

/* stylelint-disable */
#header-teleport-target:empty {
  display: none;
}

#header-teleport-target {
  overflow: hidden;
}
/* stylelint-enable */

.public-page {
  &__inner {
    min-height: 50vh;
    position: relative;
  }
}

.branded-sidebar .public-page__inner {
  margin-left: 208px;
}

.navbar {
  height: $navbarHeight;
  position: relative;
  z-index: 3;

  &__wrapper {
    height: $navbarHeight;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    border-bottom: 1px solid $color-grey200;
  }

  &__inner {
    height: 100%;
    max-width: $large;
    position: relative;
    padding: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .logo {
    height: 28px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-black;

    &__image {
      height: 100%;
    }
    &__text {
      padding-left: 8px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.public-section {
  color: $color-black;
  max-width: $large;
  margin: auto;
  padding: 40px;
  text-align: center;

  .headline {
    color: $color-black;
    font-size: 36px;
    line-height: 48px;
  }

  .narrow {
    max-width: 500px;
    color: $color-grey400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
    padding: 22px 0 40px;
  }
}

.image-list {
  max-width: $large;
  margin: auto;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__item {
    height: 50px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      zoom: 0.5;
    }
  }
}

.public-footer {
  background: white;
  padding: 32px 0;
  text-align: center;

  &__details {
    font-size: 14px;
    line-height: 26px;

    .logo {
      height: 50px;
      padding-bottom: 25px;
    }

    .headline {
      font-weight: bold;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

@include small-down {
  .logo__text {
    display: none;
  }
}
</style>
